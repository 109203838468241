 

/* -------------------------------------------------------------------------------------------------------- */
/* best CSS */
  /* .post {
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    text-align: left;
  }
  
  .post h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .post p {
    margin-bottom: 20px;
    color: #555;
  }
  
  .post-images {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .post-images img {
    height: 200px;
    flex-shrink: 0;
    border-radius: 5px;
  }
  
  .delete-button {
    background: red;
    color: white;
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: darkred;
  }
  
  .post p {
    font-size: 0.9rem;
    color: #777;
  }
  
 
  @media (max-width: 768px) {
    .post {
      width: 95%;
      padding: 15px;
    }
  
    .post-images img {
      height: 150px;
    }
  }
   */


  

/* ------------------------------------------------------------------------------------------------------- */



.post {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  padding: 25px;
  margin: 25px auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 700px;
  width: 100%;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s;
  animation: fadeInUp 0.7s ease;
}

@keyframes fadeInUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.post:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.post h3 {
  margin-bottom: 15px;
  color: #333;
  font-size: 24px;
  transition: color 0.3s;
  animation: fadeInDown 1s ease;
}

.post h3:hover {
  color: #1cb5e0;
}

.post p {
  margin-bottom: 20px;
  color: #555;
  font-size: 18px;
  line-height: 1.6;
  animation: fadeInLeft 1s ease;
}

@keyframes fadeInLeft {
  from { transform: translateX(-20px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

.post-images {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  margin-bottom: 15px;
  animation: fadeInRight 1s ease;
}

@keyframes fadeInRight {
  from { transform: translateX(20px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

.post-images img {
  /* height: 220px; */
  height:100%;
  flex-shrink: 0;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.post-images img:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.delete-button {
  background: linear-gradient(90deg, #ff6b6b, #ff4757);
  color: white;
  padding: 12px 20px;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  margin-top: 15px;
  font-size: 16px;
  transition: background 0.3s, transform 0.2s;
  animation: fadeInUp 1s ease;
}

.delete-button:hover {
  background: linear-gradient(90deg, #ff4757, #e84545);
  transform: scale(1.1);
}

.post p {
  font-size: 1rem;
  color: #777;
  transition: color 0.3s;
}

.post p:hover {
  color: #333;
}

@media (max-width: 768px) {
  .post {
    width: 95%;
    padding: 20px;
  }

  .post-images img {
    /* height: 180px; */
    height:100%;
  }
}

@media (max-width: 480px) {
  .post {
    width: 100%;
    padding: 15px;
  }

  .post-images img {
    height: 450px;
    /* height:100%; */
  }

  .post h3 {
    font-size: 20px;
  }

  .delete-button {
    padding: 10px 15px;
    font-size: 14px;
  }
}














 