 

.tag-line {
  margin-top:10px;
  margin-bottom: 5px;  
  width: 54px;  
  height: auto;  
  vertical-align: middle;  
}


/* Container Styling */
.sign-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-image: url("./Image/greenleaf.webp"); */
  background-color: rgb(247, 253, 255);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  /* Add blur effect */
  position: relative;
  z-index: 1;
}


.msme{
  display: flex;
  flex-direction: row;
  width:100%;
  justify-content: center;
  align-items: center;
  gap:30px;
  background-color: gold;
  padding:20px;
}

.msme img{
    width:80px;
}
 

.sign-in-container .logo{
  width: 7%;
  margin-bottom: -10px;
}

/* Image Styling */
.sign-in-container img {
    max-width: 60%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
    
}
/* 
.sign-in-image{
  display: flex;
    justify-content: space-between;
    gap: 20px;  
    margin-bottom: 20px;  
    width: 100%;
    flex-wrap: wrap;  
 
} */

 

 
.sign-in-image {
  display: flex;
  flex: 1; 
  flex-direction: row;  
  justify-content: center;  
  align-items: center;  
  gap: 20px;  
  margin-bottom: 20px;  
  width: 20%;  
  flex-wrap: nowrap; 
}

 
.sign-in-image .card {
  width: 100%; 
  max-width: 100%;  
  background-color: rgba(255, 255, 255, 0.2); 
  padding: 10px;  
  border-radius: 16px;  
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);  
  backdrop-filter: blur(5px); 
  -webkit-backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; 
  order: 0; 
}


/* Heading Styling */
.sign-in-container h1 {
    font-size: 2.5rem;
    background: linear-gradient(45deg, #32CD32, #00CED1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Error Message Styling */
.error-message {
    color: #ff4d4d;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

/* Button Styling */
.auth-button {
    background: linear-gradient(135deg, #32CD32, #00CED1);
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 50px;
    transition: background 0.3s ease, transform 0.3s ease;
}

.auth-button:hover {
    background: linear-gradient(135deg, #00CED1, #32CD32);
    transform: scale(1.05);
}

.gold-icon {
  color: #daa520;
  weight: 20em; /* Adjust the size as needed */
}



/* Section Styling */
.section-heading {
  font-size: 2rem;
  color: #2e8b57; /* Sea Green */
  font-weight: bold;
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.section-heading .icon {
  margin-right: 10px;
  color: #32cd32; /* Lime Green */
}

/* Paragraph Styling */
.section-paragraph {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333; /* Dark Gray */
  margin-bottom: 20px;
}

/* Vision, Mission and Work sections styling */
.vision, .mission, .work {
  padding: 20px;
  border-left: 4px solid #00ced1; /* Dark Turquoise */
  background: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Footer Styling */
footer {
  text-align: center;
  padding: 15px;
  background-color: black;
  color:white; /* Medium Gray */
  margin-top: 20px;
  font-size: 0.9rem;
  width:100%;
}



/* Work Section Styling */
.work-section {
  padding: 20px;
  text-align: center;
}
 

/* Card Container Styling */
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

/* Card Styling */
.card1 {
  display: flex;
  flex-direction: row; /* Ensures images stay in a row */
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;  
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 20px; /* Space between images */
  flex-wrap: nowrap; /* Prevents wrapping to keep images in a row */
}

/* Image Styling */
.card-image {
 
  max-width: 150px; /* Ensures images don't get too large */
  width: 20%;
  height: auto;
  border-radius: 8px;
  /* background-color: #e0e0e0;   */
  padding: 5px; /* Space inside image */
}

/* Reversible Icon Styling */
.reversible-icon {
  
  font-size: 5rem; /* Adjust icon size */
  color: #32CD32; /* Color for the reversible icon */
  transform: rotate(0deg); /* Icon orientation */
   
   
}




@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

/* Replace variables with actual values */
body {
    background-color: rgba(0, 181, 245, 0.05); /* $iconColor */
}

 

.social-container {
 
  border-top:1px solid rgb(168, 163, 163);
    width: 100%;
    text-align: center;
}

.social-icons {
    padding: 0;
    list-style: none;
    margin: 1em;
}

.social-icons li {
    display: inline-block;
    margin: 0.15em;
    position: relative;
    font-size: 1.2em;
}

.social-icons i {
    color: #fff;
    position: absolute;
    top: 21px;
    left: 21px;
    transition: all 265ms ease-out; /* $timing */
}

.social-icons a {
    display: inline-block;
}

.social-icons a:before {
    content: " ";
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: block;
    background: linear-gradient(45deg, #00B5F5, #002A8F); /* $iconColor and $accent */
    transition: all 265ms ease-out; /* $timing */
    transform: scale(1);
}

.social-icons a:hover:before {
    transform: scale(0);
    transition: all 265ms ease-in; /* $timing */
}

.social-icons a:hover i {
    transform: scale(2.2); /* Equivalent of @include transformScale(2.2) */
    color: #00B5F5; /* $iconColor */
    background: -webkit-linear-gradient(45deg, #00B5F5, #002A8F); /* $iconColor and $accent */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 265ms ease-in; /* $timing */
}




/* Responsive Design */
@media (max-width: 768px) {

  .logo{
    visibility: hidden;
  }
  .card1 {
    flex-direction: row; /* Keeps images in a row on small screens */
    flex-wrap: nowrap; /* Prevents wrapping for small screens */
  }

  .card-image{
    /* height:30px; */
    width:110px;
  }

  .reversible-icon {
    margin: 10px 0; /* Adjust margin for stacked layout */
    transform: rotate(0deg); /* Keeps icon orientation horizontal */
  }

  .sign-in-image {
    flex-direction: column; /* Keeps images in a column */
    gap: 10px; /* Reduces gap for mobile */
    width:100%;
  }

  .sign-in-image .card {
    width: 95%; /* Cards should cover full width */
    order: 2; /* Default order */
  }

  /* Reorder the second image to the top */
  .sign-in-image .card:nth-child(2) {
    order: 1; /* Moves second image to the top */
  }

  /* Hide the last image */
  .sign-in-image .card:nth-child(3) {
    display: none; /* Hides the third image */
  }
}




/* Responsive Design */
@media (max-width: 768px) {
    .sign-in-container h1 {
        font-size: 2rem;
    }

    .auth-button {
        padding: 10px 20px;
        font-size: 1rem;
    }
}

