/* .profile-view {
    max-width: 800px;
    margin: auto;
  }
  
  .profile-view h2 {
    margin-bottom: 20px;
    color: #333;
  }
   */
/* -------------------------------------------------------------------above------------------ */




  .profile-view {
    padding: 20px;
  }
  
  .profile-view h2 {
    margin-top: 0;
  }
  