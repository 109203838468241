 
.aboutus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-image: url("./Image/puppygroup.png"); */
    background-size: cover;
    background-position: center;
    height: 70vh;
    background-repeat: no-repeat;
    width: 100%;
    color:white;
    font-size: 60px;
}

.aboutus h1{
    font-size: 120px;
    font-family: "Karla", sans-serif;
}

.aboutus-page2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(255, 123, 123); */
    padding: 40px;
    gap: 20px;
}

.aboutus-page2 h1{
    font-family: "Caveat", cursive;
    font-size: 70px;
}

.aboutus-page22 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 40px;
    padding: 20px;
}

.aboutus-page22.reverse {
    flex-direction: row-reverse;
}

.aboutus-page23 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    padding: 20px;
}

.aboutus-page23 h2{
    font-family: "Caveat", cursive;
    font-size: 30px;
}

.image-container {
    position: relative;
    flex: 1;
}

.circle-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 80%;
    height: auto;
    z-index: 1;
}

.main-image {
    position: relative;
    height: auto; 
    z-index: 2;
}

.aboutus-bottom {
    font-family: "Caveat", cursive;
    font-size: 40px;
    /* background-color: antiquewhite; */
    /* background-image: url("./Image/background1.png"); */
    background-image:url(./Image/background12.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    /* gap:40px; */
}





/*  
@media (max-width: 1024px) {  
    .aboutus-page22 {
        flex-direction: column;  
        padding: 10px;
    }

    .aboutus-page23 {
        padding: 10px;  
    }

    .aboutus-page2 h1 {
        font-size: 50px;  
    }

    .aboutus-page23 h2 {
        font-size: 24px;  
    }

    .section-paragraph {
        font-size: 16px;  
    }

    .image-container {
        display: flex;  
        justify-content: center;
        padding-bottom: 20px;  
    }

    .circle-image,
    .main-image {
        width: 100%; 
        height: auto;  
    }

    .aboutus-bottom {
        font-size: 30px;  
        padding: 20px;  
    }
} */

@media (max-width: 468px) { /* Mobile devices */
    .aboutus-page2{
        padding:1px;
    }
    .aboutus-page22 {
        flex-direction: column-reverse; /* Stack image and text */
        padding: 10px;
    }

    .aboutus-page22.reverse{
        flex-direction: column-reverse;
    }

    .aboutus-page23 {
        padding: 10px; /* Reduce padding */
    }

    .aboutus-page2 h1 {
        font-size: 40px; /* Smaller heading size */
    }

    .aboutus-page23 h2 {
        font-size: 20px; /* Smaller subheading size */
    }

    .section-paragraph {
        font-size: 14px; /* Smaller paragraph size */
    }

    .image-container {
        display: flex; /* Center image */
        justify-content: center;
        padding-bottom: 20px; /* Space between image and text */
    }

    .circle-image,
    .main-image {
        width: 100%; /* Full width */
        height: auto; /* Maintain aspect ratio */
    }

    .aboutus-bottom {
        font-size: 24px; /* Smaller font size */
        padding: 20px; /* Add some padding */
    }
}

/* Global Styles for Mobile and Tablet Devices */
/* .aboutus-page2,
.aboutus-page22,
.aboutus-page23,
.image-container {
    width: 100%;  
    max-width: 100%;  
    box-sizing: border-box; 
    margin: 0;  
} */

/* .aboutus-page22.reverse {
    flex-direction: column-reverse;  
} */

