/* App.css */
.App {
  text-align: center;
  
}
 

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.brand {
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
}

.menu-button {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.header-buttons {
  display: flex;
  gap: 20px;
}

.header-buttons.open {
  display: block;
}

.signin-link, .profile-link, .search-link, .upload-link {
  background-color: rgb(191, 214, 255); 
  background:transparent;
  transition: background 0.3s ease, transform 0.2s;
  color: white;
  padding: 10px 20px;
  border: 3px solid cyan;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  
}

.upload-link{
  background:rgb(5, 116, 172);
}

.signin-link:hover, .profile-link:hover, .search-link:hover, .upload-link:hover {
background: linear-gradient(90deg, rgba(137,39,228,1) 0%, rgba(150,130,226,1) 47%, rgba(149,130,222,1) 100%);
transform: scale(1.05);
}



.success-message {
  color: green;
  margin-top: 10px;
}






/* -------------------------------------------------------------------------------- */

.App {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  text-align: center;
  padding: 20px;
  /* background-image: url(https://images.pexels.com/photos/694740/pexels-photo-694740.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1); */
  /* background: linear-gradient(to right, #e0eafc, #cfdef3); */
  /* background-image:url('./Image/background2.jpg'); */
 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  animation: fadeInBackground 2s ease;
}

@keyframes fadeInBackground {
  from { opacity: 0; }
  to { opacity: 1; }
}

.content {
  margin-top: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* max-width: 800px; */
  max-width:98%;
  width: 100%;
  animation: fadeIn 1s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.success-message, .error-message {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  color: white;
  font-weight: bold;
  animation: slideIn 0.5s ease;
}

.success-message {
  background-color: #4caf50;
}

.error-message {
  background-color: #f44336;
}

@keyframes slideIn {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

/* Header Styling */
.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #1cb5e0 0%, #000851 100%);
  padding: 20px 30px;
  color: white;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 1000;
}

.brand {
  cursor: pointer;
  font-size: 32px;
  font-weight: bold;
  font-family: 'Pacifico', cursive;
  transition: transform 0.3s;
}

.brand:hover {
  transform: scale(1.1);
}

.menu-button {
  display: none;
  font-size: 30px;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
}

.header-buttons {
  display: flex;
  gap: 15px;
}

.header-buttons a, .auth-button {
  color: white;
  text-decoration: none;
  padding: 10px 25px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.2s;
}

.header-buttons a:hover, .auth-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
  transform: scale(1.05);
}

.auth-button {
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.header-buttons.open {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #1cb5e0;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
}

/* .header-buttons.open a, .header-buttons.open .auth-button {
  width: 100%;
} */

/* Responsive Menu */
@media (max-width: 768px) {
  .header-buttons {
    display: none;
  }

  .header-buttons.open {
    display: flex;
    margin-right:10px;
  }

  .menu-button {
    display: block;
  }
}

@media (max-width: 600px) {
  .brand {
    font-size: 24px;
  }

  .header-buttons.open {
    right: 10px;
    top: 50px;
  }

  .header-buttons.open a, .header-buttons.open .auth-button {
    padding: 10px;
  }
}

/* Form Styling */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin: 0 auto;
  animation: zoomIn 0.5s ease;
}

@keyframes zoomIn {
  from { transform: scale(0); }
  to { transform: scale(1); }
}

.login-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  animation: fadeInDown 1s ease;
}

@keyframes fadeInDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.login-container input {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.login-container input:focus {
  border-color: #1cb5e0;
  box-shadow: 0 0 10px rgba(28, 181, 224, 0.5);
}

.login-container button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(90deg, #1cb5e0 0%, #000851 100%);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s;
  animation: fadeInUp 1s ease;
}

.login-container button:hover {
  background: linear-gradient(90deg, #1cb5e0 0%, #21a1f1 100%);
  transform: scale(1.05);
}

@keyframes fadeInUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.login-container p {
  margin-top: 10px;
  color: #666;
  cursor: pointer;
  transition: color 0.3s;
}

.login-container p:hover {
  color: #000851;
}

/* Post List */
.post-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.post {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 300px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s;
  animation: fadeInUp 0.7s ease;
}

.post:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.post img {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: transform 0.3s;
}

.post img:hover {
  transform: scale(1.05);
}

.post h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
  transition: color 0.3s;
}

.post h3:hover {
  color: #1cb5e0;
}

.post p {
  font-size: 14px;
  color: #666;
  transition: color 0.3s;
}

.post p:hover {
  color: #000851;
}

/* Profile View */
.profile-container {
  padding: 30px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  animation: fadeIn 1s ease;
}

.profile-container h2 {
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
  animation: fadeInDown 1s ease;
}

.profile-container p {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  animation: fadeInLeft 0.5s ease;
}

@keyframes fadeInLeft {
  from { transform: translateX(-20px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

.profile-container button {
  padding: 15px 30px;
  background: #4caf50;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s;
  animation: fadeInUp 1s ease;
}

.profile-container button:hover {
  background: #388e3c;
  transform: scale(1.05);
}

/* Search */
.search-container {
  padding: 30px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 0 auto;
  animation: zoomIn 0.5s ease;
}

.search-container input {
  width: calc(100% - 40px);
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-container input:focus {
  border-color: #1cb5e0;
  box-shadow: 0 0 10px rgba(28, 181, 224, 0.5);
}

.search-container button {
  padding: 15px 30px;
  background: linear-gradient(90deg, #1cb5e0 0%, #000851 100%);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s;
  animation: fadeInUp 1s ease;
}

.search-container button:hover {
  background: linear-gradient(90deg, #1cb5e0 0%, #21a1f1 100%);
  transform: scale(1.05);
}

/* -----------------------------------------------------Header-------------------------------------------------- */

/* Header.css */
.message-icon-container {
  position: relative;
  cursor: pointer;
  margin-right: 15px;
}

.message-notification {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
}

/* ChatHistory.css */
.chat-history {
  padding: 20px;
}

.chat-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
/* --------------------------------------------------------Header------------------------------------------------------ */




/* ------------------------------button--------------------------------------- */

 

/* CSS */
.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1a0663;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* ----------------------------------- */
 
  