/* .chat-page {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .chat-message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    max-width: 60%;
  }
  
  .sent {
    align-self: flex-end;
    background-color: #dcf8c6;
  }
  
  .received {
    align-self: flex-start;
    background-color: #ffffff;
  }
   */







   .chat-page {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #f5f5f5; /* Light background for the chat page */
  }
  
  .chat-message {
    margin-bottom: 10px;
    padding: 12px;
    border-radius: 10px;
    max-width: 60%;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .sent {
    align-self: flex-end;
    background-color: #e0ffe0; /* Light green background for sent messages */
    margin-left: 40%; /* Margin from the left side */
  }
  
  .received {
    align-self: flex-start;
    background-color: #ffffff; /* White background for received messages */
    margin-right: 40%; /* Margin from the right side */
  }
  
  .timestamp {
    display: block;
    font-size: 10px;
    color: #666;
    margin-top: 5px;
  }
  