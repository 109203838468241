/* .search {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .search-bar {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .search-bar:focus {
    outline: none;
    border-color: #0f9d58;
  }
  
  .post-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  

  */

/* ----------------------------------------------------------------above---------------------------- */








  .search {
    padding: 20px;
  }
  
  .search-bar {
    width: 92%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .post-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  