.post-details {
    text-align: left;
    padding: 20px;
  }

  .post-details p span{
    font-weight: bold;
  }
  
  /* .post-images img {
    width: 100px;
    height: 100px;
    margin: 10px;
    cursor: pointer;
  } */
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
  }
  
  .modal-image {
    max-width: 90%;
    max-height: 90%;
    display: block;
    margin: auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: white;
    cursor: pointer;
  }
  