/*  


   .upload-post {
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upload-post h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .upload-post input[type="text"],
  .upload-post textarea {
    width: 100%;
    margin: 10px 0;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .upload-post textarea {
    height: 100px;
    resize: none;
  }
  
  .upload-post input[type="file"] {
    margin: 20px 0;
  }
  
  .upload-button {
    font-size: 1.1rem;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #61dafb;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:hover {
    background-color: #21a1f1;
  }
  
  
  @media (max-width: 768px) {
    .upload-post {
      width: 95%;
    }
  }
   



  */



/* ---------------------------------------------------------------------------------------------------------------------------------- */

   
  .upload-post {
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  
  .upload-post h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.5rem;
  }
  
  .upload-post input[type="text"],
  .upload-post textarea {
    width: 100%;
    margin: 10px 0;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .upload-post textarea {
    height: 150px;
    resize: vertical;
  }
  
  .upload-post input[type="file"] {
    margin: 20px 0;
    font-size: 1rem;
  }
  
  .upload-button {
    width: 20%;
    font-size: 1.1rem;
    padding: 15px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #61dafb;
    color: white;
    transition: background-color 0.3s ease;
    box-sizing: border-box;
  }
  
  .upload-button:hover {
    background-color: #21a1f1;
  }
  
  @media (max-width: 768px) {
    .upload-post {
      padding: 15px;
    }
  
    .upload-post h2 {
      font-size: 1.3rem;
    }
  
    .upload-button {
      font-size: 1rem;
      padding: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .upload-post {
      padding: 10px;
    }
  
    .upload-post h2 {
      font-size: 1.2rem;
    }
  
    .upload-button {
      font-size: 0.9rem;
      padding: 10px;
    }
  }  
   

   /* -------------------------------------------above------------------------- */






   /* UploadPost.css */
/*  
.upload-post {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  max-width: 600px;
  margin: 0 auto;
}

.upload-post h2 {
  margin-top: 0;
}

.upload-post input,
.upload-post textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.upload-post input[type="file"] {
  padding: 3px;
}

.upload-button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.error-message {
  color: red;
}   */
